*,*::before,*::after {
    margin: 0;
    padding: 0;
    border: 0;
    outline: 0;
    list-style: none;
    text-decoration: none;
    box-sizing: border-box;
    outline: none;
}

body {
    font-family: 'IBM Plex Serif';
    cursor: none;
}

a {
    cursor: pointer;
    color: inherit;
    text-decoration: none;
    transition: none;
}

img {
    display: block;
    object-fit: cover;
    width: 100%;
}

@media (max-width: 609px) {

    body {
        cursor: auto;
    }

    .cursor {
      display: none;
    }
}