.rightpane_container {
    flex: 1;
    height: 100vh;
    overflow-y: scroll;
}

.rightpane_container::-webkit-scrollbar {
    display: none;
}

.box2 {
    width: 100%;
    aspect-ratio: 1/1;
    flex-grow: 1;
}


@media (max-width: 1143px) {

}


/* Mobile View  */

@media (max-width: 609px) {

    .rightpane_container {
        display: grid;
        grid-auto-flow: column;
        grid-auto-columns: 100%;
        overflow-x: scroll;
    }

    .box {
        scroll-snap-align: start;
    }


}

