
.leftpane_container {
    flex: 1;
    height: 100vh;
    overflow-y: scroll;
}
  
.leftpane_container::-webkit-scrollbar {
    display: none;
}
  
.box {
    position: relative;
    width: 100%;
    aspect-ratio: 1/1;
    flex-grow: 1;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-end;
}
  
.pane_text-wrapper {
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    height: 50%;
    z-index: 1;
}
  
.box h2 {
    font-family: 'Roboto', sans-serif;
    font-weight: 900;
    font-size: 40px;
    letter-spacing: 4px;
    margin-bottom: 25px;
    width: 566px;
    text-align: center;
}
  
.box p {
    font-family: 'Roboto', sans-serif;
    font-weight: 500;
    font-size: 19px;
    width: 600px;
    line-height: 23px;
    text-align: justify;
}
  
.gravity_video-element {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    overflow: hidden;
}
  
.box video {
    width: 100%;
    height: 100%;
    object-fit: cover;
    opacity: 1;
    transition: opacity 0.3s ease;
}

.box:hover video {
    opacity: 0;
}
  

.bg-purple-yellow {
    background-color: #382064;
    color: #FEC37A;
}

.bg-yellow-purple {
    background-color: #FEC37A;
    color: #382064;
}

.bg-yellow-white {
    background-color: #FEC37A;
    color: white;
}

.bg-white-purple {
    background-color: white;
    color: #382064;
}

.bg-white-yellow {
    background-color: white;
    color: #FEC37A;
}

.bg-purple-white {
    background-color: #382064;
    color: white;
}



/* 1145  1399 resolution */


@media (min-width: 1145px) and (max-width: 1399px) {

    .box h2 {
        font-size: 30px;
        margin-bottom: 22px;
        letter-spacing: 3px;
        width: 445px;
        text-align: center;
    }
    
    .box p {
        font-size: 16px;
        width: 515px;
        line-height: 23px;
        text-align: justify;
    }
}


/* 610  1144 resolution */


@media (min-width: 610px) and (max-width: 1144px) {

    .box h2 {
        font-size: 45px;
        letter-spacing: 4px;
    }
      
    .box p {
        font-size: 23px;
        line-height: 25px;
    }
}


/* Mobile View  */


@media (max-width: 609px) {

    .box h2 {
        font-size: 20px;
        margin-bottom: 22px;
        width: 80%;
        text-align: center;
    }
    
    .box p {
        font-size: 12px;
        width: 80%;
        line-height: 17px;
        text-align: justify;
    }

    .pane_text-wrapper {
        height: 60%;
    }

    
    .leftpane_container {
        display: grid;
        grid-auto-flow: column;
        grid-auto-columns: 100%;
        overflow-y: scroll;
    }

}

