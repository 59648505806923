
.nav__container {
    position: relative;
    position: fixed;
    z-index: 100;
    top: 0;
    left: 0;
    width: 40px;
    height: 100vh;
    padding: 10px;
    background-color: #382064;
}

.nav__container-wrapper {
    display: block;
    width: 100%;
    height: 100%;
    position: relative;
}

.nav__icon {
    width: 20px;
    height: 30px;
    position: absolute;
    top: 0;
    left: 0;
    cursor: pointer;
}

.icon__lines {
    pointer-events: all;
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    transition: all .2s ease-in-out;
    position: relative;
}

.line-1, .line-2, .line-3, .line-4 {
    display: block;
    position: absolute;
    width: 2px;
    height: 100%;
    border-radius: 0;
    background-color: #FEC37A;
    transition: all .2s ease-in-out;
    transform: rotate(0);
}

.line-1 {
    left: 0;
    transform-origin: top left;
}

.line-2 {
    left: 6px;
}

.line-3 {
    left: 12px;
}

.line-4 {
    left: 18px;
    transform-origin: top left;
}

.logo {
    display: inline-block;
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    height: auto;
    padding-bottom: 0;
}

/* navbar menu */

.nav__menu {
    position: fixed;
    z-index: 99;
    top: 0;
    left: 40px;
    width: auto;
    height: 100vh;
    transition: all .3s ease-in-out;
    transform: translateX(0);
}

.menu__show {
    transform: translateX(-900px);
}

.menu__wrapper {
    display: flex;
    width: auto;
    height: 100%;
    position: relative;
    z-index: 2;
}

.nav__links {
    display: flex;
    height: 100%;
    width: 100%;
}

.nav__links li {
    width: 100%;
    height: 100%;
    display: flex;
    background-color: rgba(56, 32, 100, 0.5);
    max-width: 144px;
}

.nav__links li a {
    display: flex; 
    flex-direction: column;
    align-items: flex-end;
    writing-mode: vertical-lr;
    text-orientation: upright;
    border-right: 1px solid #A07A7B;
    letter-spacing: -5px;
    padding: 0 10px 15px 10px;
    height: 100%;
    font-size: 57px;
    color: #FEC37A;
    font-family: 'Roboto', sans-serif;
}

.nav__links li a:hover {
    background-color: black;
    transition: all .5s ease-in-out;
}

.is-line-show{
    opacity: 0;
    visibility: hidden;
}

.is-line-1-rotate {
    transform: translate(0,3px) rotate(-45deg);
}
.is-line-4-rotate {
    transform: translate(3px,2px) rotate(45deg);
}

.nav__border {
    border-right: 1px solid #A07A7B;
    padding-right: 9px;
}



/* 1145  1399 resolution */

@media (min-width: 1145px) and (max-width: 1399px) {

    .nav__container {
        width: 36.5px;
        padding: 6px 4px 6px 9px;
    }
    
    .nav__container-wrapper {
        width: 70%;
    }

    .nav__icon {
        width: 20px;
        height: 26px;
    }
    
    .line-1, .line-2, .line-3, .line-4 {
        width: 2px;
    }
    
    .line-1 {
        left: 0;
    }
    
    .line-2 {
        left: 5px;
    }
    
    .line-3 {
        left: 10px;
    }
    
    .line-4 {
        left: 15px;
    }
    
    .logo {
        width: 100%;
        padding-bottom: 0;
    }
    
    /* navbar menu */
    
    .nav__menu {
        left: 32px;
    }
    
    .menu__show {
        transform: translateX(-900px);
    }
    
    .nav__links li {
        max-width: 144px;
    }
    
    .nav__links li a {
        letter-spacing: -6px;
        padding: 0px 9px 14px 9px;
        font-size: 41px;
    }
    
    .is-line-1-rotate {
        transform: translate(0,3px) rotate(-45deg);
    }

    .is-line-4-rotate {
        transform: translate(3px,2px) rotate(45deg);
    }

    .nav__border {
        padding-right: 3px;
    }
}

@media (max-width: 609px) {

    .nav__container {
        width: 36.5px;
        padding: 6px 4px 6px 9px;
    }
    
    .nav__container-wrapper {
        width: 70%;
    }

    .nav__icon {
        width: 20px;
        height: 26px;
    }
    
    .line-1, .line-2, .line-3, .line-4 {
        width: 2px;
    }
    
    .line-1 {
        left: 0;
    }
    
    .line-2 {
        left: 5px;
    }
    
    .line-3 {
        left: 10px;
    }
    
    .line-4 {
        left: 15px;
    }
    
    .logo {
        width: 100%;
        padding-bottom: 0;
    }
    
    /* navbar menu */
    
    .nav__menu {
        left: 32px;
    }
    
    .menu__show {
        transform: translateX(-900px);
    }
    
    .nav__links li {
        max-width: 144px;
        background-color: rgba(56, 32, 100, 1);
    }
    
    .nav__links li a {
        letter-spacing: -6px;
        padding: 0px 9px 14px 9px;
        font-size: 41px;
    }
    
    .is-line-1-rotate {
        transform: translate(0,3px) rotate(-45deg);
    }

    .is-line-4-rotate {
        transform: translate(3px,2px) rotate(45deg);
    }

    .nav__border {
        padding-right: 3px;
    }
}