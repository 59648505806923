.cursor {
    width: 20px;
    height: 20px;
    border: 4px solid #382064;
    background-color: #FEC37A;
    border-radius: 50%;
    position: fixed;
    z-index: 9999;
    animation: cursorAnim .5s infinite alternate;
    pointer-events: none;
}

.cursor::after {
    content: '';
    width: 36px;
    height: 36px;
    position: fixed;
    border: 7px solid rgb(194, 194, 194);
    border-radius: 50%;
    opacity: 0.5;
    top: -12px;
    left: -12px;
    animation: cursorAnim2 .5s infinite alternate;
}


@keyframes cursorAnim {
    from {
        transform: scale(1);
    }
    to {
        transform: scale(0.6);
    }
}

@keyframes cursorAnim2 {
    from {
        transform: scale(1);
    }
    to {
        transform: scale(0.3);
    }
}
