.home_container {
    width: calc(100% - 40px);
    float: right;
}

/* gravity-video */

.gravity_video-container {
    width: 100%;
    position: relative;
    margin-bottom: -5px;
    z-index: -1;
}
.gravity_video-container video {
    width: 100%;
    top: 0;
    left: 0;
    object-fit: cover;
}

.gravity_logo-container {
    display: flex;
}

.gravity_logo-container img {
    position: absolute;
    opacity: 0;
}

.gravity_logo-container img:nth-child(1) {
    animation: logoAnimation 20s infinite;
    top: 27%;
    left: 37.5%;
    width: 25%;
}
  
.gravity_logo-container img:nth-child(2) {
    animation: logoAnimation 20s infinite 10s;
    top: 44.5%;
    left: 32%;
    width: 37%;
}


/* service */

.gravity_services-container {
    width: 100%;
    background-color: white;
    color: #382064;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 30px 40px;
    font-family: 'Roboto', sans-serif;
    font-weight: 700;
    font-size: 27px;
    letter-spacing: 2px;
    overflow-x: hidden;
    position: relative;
}

.gravity_services-container img {
    width: 25%;
    position: absolute;
    z-index: 1;
    left: calc(45% - 130px);
}

.gravity_services-container p {
    margin: 10px 0;
}

.gravity_services-left {
    text-align: left;
    width: 620px;
}

.gravity_services-right {
    text-align: right;
}

/* complete-work */

.gravity_complete-work-top {
    display: flex;
    flex-direction: column;
    background-color: #FEC37A;
    padding: 30px 40px;
    left: 0;
    white-space: nowrap; 
    overflow-x: hidden;
}

.gravity_complete-work-top p {
    margin: 10px 0;
    letter-spacing: 4px;
    font-size: 40px;
    color: #382064;
    font-family: 'Roboto', sans-serif;
    font-weight: 900;
}

.text2 {
    position: relative;
}
  
.moving-text {
    display: inline-block;
}
  
.text1 {
    animation: moveText1 15s linear infinite; 
}
  
.text2 {
    animation: moveText2 15s linear infinite; 
}
  
.text3 {
    animation: moveText3 17s linear infinite; 
}

.text4 {
    animation: moveText4 17s linear infinite; 
}

.text5 {
    animation: moveText5 15s linear infinite; 
}
  

.gravity_complete-work-bottom {
    width: 100%;
    display: flex;
    justify-content: space-between;
    background-color: black;
    padding: 40px 80px;
}

.gravity_complete-work-bottom .text {
    flex: 1;
    display: flex;
    flex-direction: column;
    justify-content: center;
}
.gravity_complete-work-bottom .image {
    flex: 1;
    display: flex;
    align-items: center;
}

.gravity_complete-work-bottom h2 {
    font-family: 'Roboto', sans-serif;
    font-weight: 900;
    font-size: 40px;
    color: white;
    letter-spacing: 4px;
    margin-bottom: 40px;
}

.gravity_complete-work-bottom p {
    font-family: 'Roboto', sans-serif;
    font-weight: 400;
    color: white;
    font-size: 16px;
    width: 587px;
    letter-spacing: 4px;
    line-height: 30px;
    margin-bottom: 40px;
}

.gravity_complete-work-bottom h3 {
    font-family: 'Roboto', sans-serif;
    font-weight: 500;
    color: white;
    font-size: 27px;
    letter-spacing: 4px;
    width: 587px;
    margin-bottom: 40px;
}

.gravity_complete-work-bottom h3 .cyan {
    color: #06FDDD;
}

.gravity_complete-work-bottom h3 .yellow {
    color: #F9C50F;
}

.gravity_complete-work-bottom h3 .pink {
    color: #FB5279;
}

.gravity_complete-work-bottom h3 .orange {
    color: #FB5D0F;
}

/* impact */

.gravity_impact-container {
    display: flex;
    justify-content: space-between;
    padding: 40px 80px;
    background-color: white;
}

.gravity_impact-container .text {
    flex: 1;
    margin-left: 50px;
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    justify-content: center;
}
.gravity_impact-container .image {
    flex: 1;
    display: flex;
    align-items: center;
}

.gravity_impact-container h2 {
    font-family: 'Roboto', sans-serif;
    font-weight: 900;
    font-size: 40px;
    color: #010039;
    letter-spacing: 4px;
    margin-bottom: 40px;
    text-align: right;
}

.gravity_impact-container .dark-b-text {
    font-family: 'Roboto', sans-serif;
    font-weight: 300;
    color: #010039;
    font-size: 16px;
    letter-spacing: 3px;
    text-align: right;
    margin-bottom: 40px;
    width: 569px
}

.gravity_impact-container .red-text {
    font-family: 'Roboto', sans-serif;
    font-weight: 500;
    color: #EC1840;
    font-size: 18px;
    letter-spacing: 3px;
    text-align: right;
    margin-bottom: 40px;
    width: 521px;
}

/* responsibility */

.gravity_responsibility-container {
    padding: 40px;
    background-color: black;
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.gravity_responsibility-container h2 {
    font-family: 'Roboto', sans-serif;
    font-weight: 900;
    font-size: 40px;
    color: #06FDDD;
    letter-spacing: 4px;
    margin-bottom: 30px;
    text-align: center;
}


.gravity_responsibility-container p {
    font-family: 'Roboto', sans-serif;
    font-weight: 500;
    color: white;
    font-size: 18px;
    letter-spacing: 3px;
    text-align: left;
}

/* two pane */

.pane_container {
    display: flex;
    width: 100%;
}




/* 1145  1399 resolution */

@media (min-width: 1145px) and (max-width: 1399px) {

    .home_container {
        width: calc(100% - 32px);
    }

    /* service */

    .gravity_services-container {
        padding: 20px 30px;
        font-size: 22px;
        letter-spacing: 1px;
    }

    .gravity_services-container img {
        left: calc(50% - 115px);
    }
    

    .gravity_services-left {
        width: 533px;
    }

    /* complete-work */

    .gravity_complete-work-top {
        padding: 20px 30px;
    }
    
    .gravity_complete-work-top p {
        margin: 5px 0;
        letter-spacing: 2px;
        font-size: 28px;
    }

    .gravity_complete-work-bottom {
        padding: 30px 45px;
    }

    .gravity_complete-work-bottom h2 {
        font-size: 30px;
        letter-spacing: 2px;
        margin-bottom: 30px;
    }

    .gravity_complete-work-bottom p {
        font-size: 13px;
        width: 421px;
        letter-spacing: 2px;
        line-height: 30px;
        margin-bottom: 40px;
    }

    .gravity_complete-work-bottom h3 {
        font-size: 18px;
        letter-spacing: 2px;
        width: 370px;
        margin-bottom: 30px;
    }

    /* impact */

    .gravity_impact-container {
        padding: 30px 50px;
    }

    .gravity_impact-container .text {
        margin-left: 30px;
    }

    .gravity_impact-container h2 {
        font-size: 30px;
        letter-spacing: 2px;
        margin-bottom: 30px;
    }

    .gravity_impact-container .dark-b-text {
        font-size: 13px;
        letter-spacing: 2px;
        margin-bottom: 30px;
        width: 390px
    }

    .gravity_impact-container .red-text {
        font-size: 15px;
        letter-spacing: 2px;
        margin-bottom: 30px;
        width: 392px;
    }

    /* responsibility */

    .gravity_responsibility-container {
        padding: 30px;
    }

    .gravity_responsibility-container h2 {
        font-size: 30px;
        letter-spacing: 2px;
        margin-bottom: 20px;
    }

    .gravity_responsibility-container p {
        font-size: 15px;
        letter-spacing: 2px;
    }

}

/* mobile view  */

@media (min-width: 610px) and (max-width: 1144px) {

    .home_container {
        width: calc(100% - 32px);
    }

    /* service */


    .gravity_services-container {
        display: flex;
        flex-direction: column;
        padding: 20px 30px;
        letter-spacing: 1px;
    }

    .gravity_services-right {
        font-size: 20px;
        text-align: center;
        width: 100%;
    }

    .gravity_services-container img {
        left: calc(50% - 115px);
    }

    .gravity_services-left {
        font-size: 20px;
        width: 100%;
        text-align: center;
    }

    .gravity_services-container img {
        display: none;
    }

    /* complete-work */

    .gravity_complete-work-top {
        padding: 20px 30px;
    }
    
    .gravity_complete-work-top p {
        margin: 5px 0;
        letter-spacing: 2px;
        font-size: 20px;
    }

    .gravity_complete-work-bottom {
        padding: 30px 45px;
    }

    .gravity_complete-work-bottom h2 {
        font-size: 30px;
        letter-spacing: 2px;
        margin-bottom: 30px;
    }

    .gravity_complete-work-bottom p {
        font-size: 13px;
        width: 421px;
        letter-spacing: 2px;
        line-height: 30px;
        margin-bottom: 40px;
    }

    .gravity_complete-work-bottom h3 {
        font-size: 18px;
        letter-spacing: 2px;
        width: 370px;
        margin-bottom: 30px;
    }

    /* impact */

    .gravity_impact-container {
        padding: 30px 50px;
    }

    .gravity_impact-container .text {
        margin-left: 30px;
    }

    .gravity_impact-container h2 {
        font-size: 30px;
        letter-spacing: 2px;
        margin-bottom: 30px;
    }

    .gravity_impact-container .dark-b-text {
        font-size: 13px;
        letter-spacing: 2px;
        margin-bottom: 30px;
        width: 390px
    }

    .gravity_impact-container .red-text {
        font-size: 15px;
        letter-spacing: 2px;
        margin-bottom: 30px;
        width: 392px;
    }

    /* responsibility */

    .gravity_responsibility-container {
        padding: 30px;
    }

    .gravity_responsibility-container h2 {
        font-size: 30px;
        letter-spacing: 2px;
        margin-bottom: 20px;
    }

    .gravity_responsibility-container p {
        font-size: 15px;
        letter-spacing: 2px;
    }

    .pane_container {
        display: block;
        width: 100%;
    }

}

/* Mobile View  */

@media (max-width: 609px) {

    .home_container {
        width: calc(100% - 32px);
    }

    /* gravity-video */

    .gravity_video-container {
        width: 100%;
        position: relative;
        margin-bottom: -5px;
        z-index: -1;
    }
    .gravity_video-container video {
        height: 90vh;
        top: 0;
        left: 0;
        object-fit: cover;
    }

    .gravity_logo-container img:nth-child(1) {
        top: 36%;
        left: 28%;
        width: 45%;
    }
      
    .gravity_logo-container img:nth-child(2) {
        top: 46.5%;
        left: 22%;
        width: 57%;
    }

    /* service */

    .gravity_services-container {
        display: flex;
        flex-direction: column;
        padding: 10px 15px;
        letter-spacing: 1px;
    }

    .gravity_services-right {
        font-size: 12px;
        text-align: center;
        width: 100%;
    }

    .gravity_services-container img {
        left: calc(50% - 115px);
    }

    .gravity_services-left {
        font-size: 12px;
        width: 100%;
        text-align: center;
    }

    .gravity_services-container img {
        display: none;
    }

    /* complete-work */

    .gravity_complete-work-top {
        padding: 10px 15px;
    }
    
    .gravity_complete-work-top p {
        margin: 5px 0;
        letter-spacing: 1px;
        font-size: 13px;
    }

    .gravity_complete-work-bottom {
        padding: 20px 20px;
        flex-direction: column;
    }

    .gravity_complete-work-bottom h2 {
        font-size: 20px;
        letter-spacing: 2px;
        margin-bottom: 30px;
    }

    .gravity_complete-work-bottom p {
        font-size: 12px;
        width: 100%;
        letter-spacing: 2px;
        line-height: 25px;
        margin-bottom: 25px;
    }

    .gravity_complete-work-bottom h3 {
        font-size: 17px;
        letter-spacing: 2px;
        width: 100%;
        margin-bottom: 25px;
    }

    .text1 {
        animation: moveText_1 15s linear infinite; 
    }
      
    .text2 {
        animation: moveText_2 15s linear infinite; 
    }
      
    .text3 {
        animation: moveText_3 17s linear infinite; 
    }
    
    .text4 {
        animation: moveText_4 17s linear infinite; 
    }
    
    .text5 {
        animation: moveText_5 15s linear infinite; 
    }


    /* impact */

    .gravity_impact-container {
        flex-direction: column;
        align-items: center;
        justify-content: center;
        padding: 20px 20px;
    }

    .gravity_impact-container .text {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        margin-left: 0px;
    }

    .gravity_impact-container .image {
        display: none;
    }

    .gravity_impact-container h2 {
        font-size: 20px;
        letter-spacing: 2px;
        margin: 0 0 30px 0;
        text-align: center;
        width: 100%;
    }

    .gravity_impact-container .dark-b-text {
        text-align: center;
        font-size: 13px;
        letter-spacing: 2px;
        margin-bottom: 25px;
        width: 100%;
    }

    .gravity_impact-container .red-text {
        font-size: 13px;
        letter-spacing: 2px;
        margin-bottom: 15px;
        width: 100%;
        text-align: center;
    }

    /* responsibility */

    .gravity_responsibility-container {
        padding: 30px;
    }

    .gravity_responsibility-container h2 {
        font-size: 20px;
        letter-spacing: 2px;
        margin-bottom: 30px;
    }

    .gravity_responsibility-container p {
        font-size: 12px;
        letter-spacing: 2px;
    }

    .pane_container {
        display: flex;
        flex-direction: column;
        width: 100%;
    }

    @keyframes moveText_1 {
        0%, 100% {
          transform: translateX(0vw);
        }
        50% {
          transform: translateX(calc(100vw - 83vw)); 
        }
    }
      
    @keyframes moveText_2 {
        0%, 100% {
          transform: translateX(0vw);
        }
        50% {
          transform: translateX(calc(100vw - 97vw)); 
        }
    }
      
    @keyframes moveText_3 {
        0%, 100% {
          transform: translateX(0vw);
        }
        50% {
          transform: translateX(calc(100vw - 76vw)); 
        }
    }
      
    @keyframes moveText_4 {
        0%, 100% {
          transform: translateX(0vw);
        }
        50% {
          transform: translateX(calc(100vw - 97vw)); 
        }
    }
      
    @keyframes moveText_5 {
        0%, 100% {
          transform: translateX(0vw);
        }
        50% {
          transform: translateX(calc(100vw - 57vw)); 
        }
    }
}


/* Animations */

@keyframes moveText1 {
    0%, 100% {
      transform: translateX(0vw);
    }
    50% {
      transform: translateX(calc(100vw - 51vw)); 
    }
}
  
@keyframes moveText2 {
    0%, 100% {
      transform: translateX(0vw);
    }
    50% {
      transform: translateX(calc(100vw - 64vw)); 
    }
}
  
@keyframes moveText3 {
    0%, 100% {
      transform: translateX(0vw);
    }
    50% {
      transform: translateX(calc(100vw - 48vw)); 
    }
}
  
@keyframes moveText4 {
    0%, 100% {
      transform: translateX(0vw);
    }
    50% {
      transform: translateX(calc(100vw - 64vw)); 
    }
}
  
@keyframes moveText5 {
    0%, 100% {
      transform: translateX(0vw);
    }
    50% {
      transform: translateX(calc(100vw - 36vw)); 
    }
}

@keyframes logoAnimation {
    0%, 100% {
      transform: scale(1);
      opacity: 1;
    }

    38%, 62% {
        transform: scale(0.5);
        opacity: 0;
    }
}