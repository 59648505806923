footer {
    overflow-y: hidden;
    background-color: #2e1a51;
    color: #FEC37A;
    padding: 10px 15px;
    font-weight: bold;
    font-family: 'Roboto', sans-serif;
}

.footer_top h1 {
    font-size: 56px;
    margin-bottom: 60px;
}

.footer_bottom {
    display: flex;
    width: 100%;
}

.footer_left {
    width: 50%;
}

.footer_right {
    width: 50%;
    padding: 0 0 0 20%;
}

.footer_wrapper-link {
    border: 1px solid #FEC37A;
    margin-bottom: 10px;
    padding: 10px 0 34px 10px;
}

.footer_wrapper-link:hover {
    background-color: white;
    border: 1px solid white;
    color: black;
    transition: ease-in-out 0.3s;
}

.footer_wrapper-link a {
    font-size: 20px;
    line-height: 24px;
}

.footer_right h2 {
    font-size: 32px;
    line-height: 36px;
}

.footer_form .footer_email {
    border: 0;
    background-color: rgba(0,0,0,0);
    padding-bottom: 18px;
    border-bottom: 1px solid #FEC37A;
    font-size: 14px;
    line-height: 18px;
    width: 100%;
    margin-top: 60px;
}

.footer_form input::placeholder {
    color: #FEC37A;
    font-size: 20px;
}

.privacy_policy {
    display: flex;
    align-items: center;
    padding-top: 17px;
}

.footer_checkbox-custom {
    display: flex;
    justify-content: center;
    align-items: center;
}

.footer_checkbox-custom label {
    display: inline-block;
    cursor: pointer;
    position: relative;
    padding-left: 25px;
    margin-right: 15px;
    font-size: 17px;
}
  
.footer_checkbox-custom label:before {
    content: "";
    display: inline-block;
    width: 16px;
    height: 16px;
    margin-right: 10px;
    position: absolute;
    left: 0;
    bottom: 1px;
    background-color: #2e1a51;
    border: 1px solid #FEC37A;
}
  
.footer_checkbox-custom input[type=checkbox] {
    display: none;
}

  
.footer_checkbox-custom input[type=checkbox]:checked + label:before {
    content: "\2713";
    text-shadow: 1px 1px 1px rgba(0, 0, 0, .2);
    font-size: 12px;
    color: #FEC37A;
    text-align: center;
    line-height: 12px;
}


.footer_img-div {
    margin-top: 80px;
    width: 90%;
    animation: moveFooterText 15s linear infinite; 
}

@keyframes moveFooterText {
    0%, 100% {
      transform: translateX(0vw);
    }
    50% {
      transform: translateX(calc(100vw - 90vw)); 
    }
}

.footer_img-div img{
    padding: 48px 15px;
}

.footer_contacts-div {
    display: flex;
    width: 100%;
}

.footer_contacts {
    flex: 1;
    font-size: 24px;
    line-height: 24px;
}

.contacts-wrapper {
    width: 470px;
}

.contacts-info {
    margin-bottom: 5px;
}

.footer_social-media {
    display: flex;
    flex: 1;
    font-size: 24px;
    line-height: 24px;
}

.social-left,.social-right {
    display: flex;
    flex-direction: column;
}

.footer_social-media .social-media {
    margin: 0 0 10px 20px;
}

.footer_copyrights {
    flex: 1;
    font-size: 14px;
}

.copyrights {
    display: flex;
    align-items: center;
}

.copyright-icon {
    margin-right: 4px;
}

/* 1145  1399 resolution */

@media (min-width: 1145px) and (max-width: 1399px) {

    .footer_top h1 {
        font-size: 46px;
        margin-bottom: 40px;
    }
    
    .footer_right {
        width: 50%;
        padding: 0 0 0 20%;
    }
    
    .footer_wrapper-link {
        margin-bottom: 10px;
        padding: 10px 0 18px 10px;
    }
    
    .footer_wrapper-link a {
        font-size: 15px;
        line-height: 24px;
    }
    
    .footer_right h2 {
        font-size:22px;
        line-height: 24px;
    }
    
    .footer_form .footer_email {
        border: 0;
        padding-bottom: 15px;
        font-size: 14px;
        line-height: 18px;
        margin-top: 60px;
        border-bottom: 1px solid #FEC37A;
    }
    
    .footer_form input::placeholder {
        font-size: 15px;
    }
    
    .privacy_policy {
        padding-top: 17px;
    }
  
    .footer_checkbox-custom label { 
        font-size: 15px;
    }

    .footer_img-div {
        margin-top: 37px;
    }
    
    .footer_img-div img{
        padding: 34px 15px;
    }
    
    .footer_contacts {
        font-size: 18px;
        line-height: 20px;
    }
    
    .contacts-wrapper {
        width: 350px;
    }
    
    .footer_social-media {
        font-size: 18px;
        line-height: 20px;
    }
    
    .footer_social-media .social-media {
        margin: 0 0 8px 15px;
    }
    
    .footer_copyrights {
        font-size: 14px;
    }
    
    .copyright-icon {
        margin-right: 4px;
    }
    
}

/* 610  1144 resolution */

@media (min-width: 610px) and (max-width: 1144px) {

    footer {
        padding: 10px 25px;
    }

    .footer_top h1 {
        font-size: 46px;
        margin-bottom: 40px;
    }
    
    .footer_bottom {
        display: block;
    }

    .footer_left {
        width: 100%;
    }

    .footer_right {
        width: 100%;
        padding: 0 0 0 0;
        margin-top: 60px;
    }
    
    .footer_wrapper-link {
        margin-bottom: 10px;
        padding: 10px 0 30px 10px;
    }
    
    .footer_wrapper-link a {
        font-size: 20px;
        line-height: 24px;
    }
    
    .footer_right h2 {
        font-size:30px;
        line-height: 24px;
    }
    
    .footer_form .footer_email {
        border: 0;
        padding-bottom: 20px;
        font-size: 14px;
        line-height: 18px;
        margin-top: 20px;
        border-bottom: 1px solid #FEC37A;
    }
    
    .footer_form input::placeholder {
        font-size: 15px;
    }
    
    .privacy_policy {
        padding-top: 20px;
    }
  
    .footer_checkbox-custom label { 
        font-size: 15px;
    }

    .footer_img-div {
        margin-top: 37px;
    }
    
    .footer_img-div img{
        padding: 34px 15px;
    }
    
    .footer_contacts-div {
        margin-bottom: 20px;
    }

    .footer_contacts {
        font-size: 18px;
        line-height: 20px;
    }
    
    .contacts-wrapper {
        width: 350px;
    }
    
    .footer_social-media {
        font-size: 18px;
        line-height: 20px;
    }
    
    .footer_social-media .social-media {
        margin: 0 0 8px 15px;
    }
    
    .footer_copyrights {
        font-size: 14px;
    }
    
    .copyright-icon {
        margin-right: 4px;
    }
}


/* Mobile View  */

@media (max-width: 609px) {

    footer {
        padding: 10px 20px;
    }

    .footer_top h1 {
        font-size: 25px;
        margin-bottom: 30px;
    }
    
    .footer_bottom {
        display: block;
    }

    .footer_left {
        width: 100%;
    }

    .footer_right {
        width: 100%;
        padding: 0 0 0 0;
        margin-top: 50px;
    }
    
    .footer_wrapper-link {
        margin-bottom: 10px;
        padding: 10px 0 20px 10px;
    }
    
    .footer_wrapper-link a {
        font-size: 12px;
        line-height: 18px;
    }
    
    .footer_right h2 {
        font-size:18px;
        line-height: 18px;
    }
    
    .footer_form .footer_email {
        border: 0;
        padding-bottom: 20px;
        font-size: 12px;
        line-height: 18px;
        margin-top: 20px;
        border-bottom: 1px solid #FEC37A;
    }
    
    .footer_form input::placeholder {
        font-size: 12px;
    }
    
    .privacy_policy {
        padding-top: 20px;
    }
  
    .footer_checkbox-custom label { 
        font-size: 12px;
    }

    .footer_img-div {
        margin-top: 20px;
    }
    
    .footer_img-div img{
        padding: 34px 15px;
    }
    
    .footer_contacts-div {
        display: flex;
        flex-direction: column;
        margin-bottom: 20px;
    }

    .footer_contacts {
        font-size: 12px;
        line-height: 15px;
    }
    
    .contacts-wrapper {
        width: 100%;
    }
    
    .footer_social-media {
        font-size: 12px;
        line-height: 18px;
    }
    
    .footer_social-media .social-media {
        margin: 0 0px 0px;
    }
    
    .footer_social-media .social-left {
        margin: 8px 0 0 0; 
    }
    
    
    .footer_social-media .social-right {
        margin: 8px 0 8px 10px;
    }
    
    .footer_copyrights {
        font-size: 8px;
    }
    
    .copyright-icon {
        margin-right: 4px;
    }

}